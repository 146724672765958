import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import LoadingTr from './components/LoadingTr';

const App = () => {
	const [bedready, setBedready] = useState([]);
	const [loading, setloading] = useState(true);
	const [timeLoad, setTimeLoad] = useState(new Date());

	useEffect(() => {
		getBedReady();
		const interval = setInterval(() => {
			getBedReady();
			setTimeLoad(new Date());
		}, 300000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBedReady = async () => {
		setloading(true);
		try {
			const result = await axios.get('https://wsdisplay.rsumponorogo.com/api/bedready');
			setBedready(result.data);
		} catch (error) {
			alert(error.message);
		} finally {
			setloading(false);
		}
	};

	return (
		<div className='p-3'>
			<main>
				<Header timeLoad={timeLoad} />
				<section>
					<div className='my-3'>
						<div className='overflow-x-auto'>
							<table className='w-full border border-slate-500 rounded-lg mb-5'>
								<thead className='bg-gray-200'>
									<tr>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '280px' }}
										>
											NAMA RUANG
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											VVIP
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											VIP
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Kelas 1
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Kelas 2
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Kelas 3
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Isolasi
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											HCU
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											ICU
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Nicu
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Perinatologi
										</th>
										<th
											scope='col'
											className='text-slate-700 py-2 px-4 border text-xl border-slate-500 uppercase'
											style={{ width: '150px' }}
										>
											Keterangan
										</th>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<LoadingTr />
									) : (
										bedready.map((item, i) => {
											return (
												<tr key={i} className='text-center'>
													<td className='px-4 py-3 border font-semibold text-xl border-slate-500 text-left'>
														{item.Ruangan}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.VVIP}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.VIP}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Kelas1}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Kelas2}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Kelas3}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Isolasi}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Hcu}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.icu}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Nicu}
													</td>
													<td className='px-4 py-3 border font-semibold text-2xl border-slate-500'>
														{item.Perinatologi}
													</td>
													<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
														{item.keterangan}
													</td>
												</tr>
											);
										})
									)}
								</tbody>
							</table>
						</div>

						<p className='font-semibold mb-1 text-lg'>
							Keterangan : Tabel diatas menunjukkan jumlah ketersediaan tempat tidur rawat inap.
							Jika angka menunjukkan angka 0 maka tempat tidur di ruang dan kelas rawat inap
							tersebut sedang penuh.
						</p>
						{/* <div className='md:flex align-items-center'>
							<div className='md:mb-0 mb-5'>
								<table className=''>
								<thead className='bg-gray-50'>
									<tr>
										<th
											className='text-gray-600 py-2 px-4 border border-slate-300 uppercase'
											style={{ width: '520px' }}
										>
											Nama Ruang
										</th>
										<th
											className='text-gray-600 py-2 px-4 border border-slate-300 uppercase'
											style={{ width: '150px' }}
										>
											Jumlah
										</th>
									</tr>
								</thead>
								<tbody>
									{bedready.map((item, index) => {
										if (item.Ruangan === 'ICCU') {
											return (
												<tr key={index} className='text-center'>
													<td
														className='px-4 py-3 border border-slate-300 text-left'
														style={{ width: '150px' }}
													>
														{item.Ruangan}
													</td>
													<td
														className='px-4 py-3 border border-slate-300 text-center'
														style={{ width: '150px' }}
													>
														{item.khusus}
													</td>
												</tr>
											);
										} else if (item.Ruangan === 'PERINATOLOGI') {
											return (
												<tr key={index} className='text-center'>
													<td className='px-4 py-3 border border-slate-300 text-left'>
														{item.Ruangan}
													</td>
													<td className='px-4 py-3 border border-slate-300 text-center'>
														{item.khusus}
													</td>
												</tr>
											);
										}
									})}
								</tbody>
							</table>
							</div>
							<div className='md:ml-10'>
								<p className='font-semibold'>
									Tabel diatas menunjukkan jumlah ketersediaan tempat tidur di ruang dan kelas
									tersebut yg kosong atau bisa ditempati untuk rawat inap pasien. Jika angka
									menunjukkan angka 0 maka tempat tidur di ruang dan kelas rawat inap tersebut
									sedang penuh.
								</p>
							</div>
						</div> */}
					</div>
				</section>
			</main>
		</div>
	);
	// return <div className='p-3'>{loading ? <LoadingSpeaner /> : renderData}</div>;
};

export default App;

import React, { useState } from 'react';

const LoadingTr = () => {
	const [arr] = useState([0, 1, 2, 3, 4, 5, 6]);
	return (
		<>
			{arr.map((val, key) => (
				<tr key={key}>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
					<td className='px-4 py-3 border font-semibold text-xl border-slate-500'>
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-8 bg-gray-200 rounded-lg dark:bg-slate-500'></div>
						</div>
					</td>
				</tr>
			))}
		</>
	);
};

export default LoadingTr;

import React from 'react';
import logo from '../logo.png';
import 'moment/locale/id';
import moment from 'moment';

const Header = ({ timeLoad }) => {
	return (
		<div className='text-center'>
			<h1 className='text-2xl mb-0 font-bold uppercase'>Info Ketersediaan Tempat Tidur</h1>
			<h1 className='uppercase text-4xl flex items-center justify-center w-full font-bold'>
				<img src={logo} alt='logo' className='w-11 mr-3 md:block hidden' />
				RSU MUHAMMADIYAH PONOROGO
			</h1>
			<h3 className='font-medium text-lg uppercase'>
				Terakhir di perbarui {moment(timeLoad).format('LLLL')} WIB
			</h3>
		</div>
	);
};

export default Header;
